import React from 'react';
import { MIcon } from '~/@material-extend';

// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  chat: <MIcon src={path('ic_chat')} />
};

export default [
  {
    items: [
      {
        title: 'chat',
        href: '/chat',
        icon: ICONS.chat
      }
    ]
  }
];
