import firebase from '~/utils/firebase';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  messages: null
};

const messages = {
  state: { ...initialState },
  reducers: {
    setMessages(state, { consultationId, messages }) {
      return {
        ...state,
        messages: { ...state.messages, [consultationId]: messages }
      };
    },
    setLastSeen(state, payload) {
      return {
        ...state,
        lastSeen: {
          ...state.lastSeen,
          [payload.consultationId]: payload.timestamp
        }
      };
    },
    resetState() {
      return initialState;
    }
  },
  effects: () => ({
    async fetchMessages(consultationId) {
      const unsubscribe = firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .orderBy('createdAt')
        .onSnapshot(querySnapshot => {
          if (document.hidden) {
            querySnapshot.docChanges().forEach(async change => {
              if (change.type === 'added') {
                const reg = await navigator.serviceWorker.getRegistration();
                if (!reg) {
                  return;
                }
                reg.showNotification('Youve got a new message!', {
                  body: 'Got a new message from patient'
                });
              }
            });
          }
          const messages = querySnapshot.docs.map(message => ({
            ...message.data(),
            id: message.id
          }));

          this.setMessages({ consultationId, messages: messages });
        });

      return unsubscribe;
    },
    async sendMessage({ text, consultationId }, state) {
      const currentUserRef = firebase
        .firestore()
        .collection('users')
        .doc(state.authentication.user?.id);

      const message = {
        createdAt: firebase.firestore.Timestamp.now(),
        sender: currentUserRef,
        text,
        type: 'text'
      };

      await firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .add(message);
    },
    async sendFile({ file, consultationId, patientId }, state) {
      const doctorId = state.authentication.user.id;
      const metadata = {
        doctorId,
        patientId,
        consultationId
      };
      const fileType = file.type.split('/')[0];
      const fileUrl = await this.uploadFile({
        file,
        fileType,
        consultationId,
        metadata
      });

      const currentUserRef = firebase
        .firestore()
        .collection('users')
        .doc(state.authentication.user?.id);

      const message = {
        createdAt: firebase.firestore.Timestamp.now(),
        sender: currentUserRef,
        file: fileUrl,
        type: fileType
      };

      await firebase
        .firestore()
        .collection(`consultations/${consultationId}/messages`)
        .add(message);
    },
    async uploadFile({ file, fileType, consultationId, metadata }) {
      const type = this.getFileExtension(fileType);
      const reference = firebase
        .storage()
        .ref(`consultations/${consultationId}/${uuidv4()}${type}`);

      const taskSnapshot = await reference.put(file, {
        customMetadata: metadata
      });

      return taskSnapshot.metadata.fullPath;
    },
    getFileExtension(type) {
      switch (type) {
        case 'image':
          return '.png';
        case 'video':
          return '.mp4';
        case 'audio':
          return '.mp3';
        default:
          return;
      }
    }
  }),
  selectors: (slice, createSelector) => ({
    consultationMessages() {
      return createSelector(
        slice,
        (state, consultationId) => consultationId,
        (state, consultationId) => state.messages?.[consultationId]
      );
    },
    lastConsultationMessage() {
      return createSelector(
        slice,
        (state, consultationId) => consultationId,
        (state, consultationId) =>
          state.messages?.[consultationId]?.slice(-1)[0]
      );
    }
  })
};

export default messages;
