const initialState = {
  darkMode: false
};

const darkMode = {
  state: { ...initialState },
  reducers: {
    toggleTheme(state) {
      return { ...state, darkMode: !state.darkMode };
    }
  },
  effects: () => ({})
};

export default darkMode;
