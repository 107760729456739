import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const user = useSelector(state => state.authentication.user);

  if (user) {
    return <Redirect to="/chat" />;
  }

  return children;
}

export default GuestProtect;
