import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const user = useSelector(state => state.authentication.user);

  if (!user) {
    return <Redirect to="/login" />;
  }

  return children;
}

export default AuthProtect;
