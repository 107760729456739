import firebase from '~/utils/firebase';

const initialState = {
  user: null
};

const authentication = {
  state: { ...initialState },
  reducers: {
    setUser(state, user) {
      return { ...state, user };
    }
  },
  effects: () => ({
    async login(user) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(user?.email, user?.password);

      await this.fetchCurrentUserDetails();
    },
    async fetchCurrentUserDetails() {
      const user = firebase.auth().currentUser;

      const documentSnapshot = await firebase
        .firestore()
        .collection('users')
        .doc(user?.uid)
        .get();

      const docData = documentSnapshot.data();

      const photo = await this.fetchPhotoUrl(docData?.photo);

      const userDetails = {
        ...docData,
        id: user?.uid,
        email: user?.email,
        photo
      };

      this.setUser(userDetails);
    },
    async fetchPhotoUrl(photo) {
      return await firebase
        .storage()
        .refFromURL(photo)
        .getDownloadURL();
    },
    async logout() {
      const user = firebase.auth().currentUser;

      if (user) {
        await firebase.auth().signOut();
      }

      this.setUser(null);
    }
  })
};

export default authentication;
