import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';
import loadingPlugin from '@rematch/loading';
import persistPlugin, { getPersistor } from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

import * as models from './models';

const redux = { middlewares: [] };

if (process.env.NODE_ENV !== 'production') {
  redux.middlewares.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'darkMode']
};

const store = init({
  models,
  plugins: [selectPlugin(), loadingPlugin(), persistPlugin(persistConfig)],
  redux
});

const persistor = getPersistor();

export { persistor };

export default store;
