import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import createAvatar from '~/utils/createAvatar';
import { MAvatar } from '~/@material-extend';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string
};

function MyAvatar({ className, ...other }) {
  const user = useSelector(state => state.authentication.user);
  const displayName = `${user?.name} ${user?.surname}`;
  const photoURL = user?.photo;
  return (
    <MAvatar
      src={photoURL}
      alt={displayName}
      color={photoURL ? 'default' : createAvatar(displayName).color}
      className={className}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}

export default MyAvatar;
