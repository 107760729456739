import firebase from '~/utils/firebase';

const initialState = {
  consultations: []
};

const consultations = {
  state: { ...initialState },
  reducers: {
    setConsultations(state, consultations) {
      return { ...state, consultations };
    },
    updateConsultations(state, { id, ...payload }) {
      return {
        ...state,
        consultations: state.consultations.map(consultation =>
          consultation.id === id
            ? { ...consultation, ...payload }
            : consultation
        )
      };
    },
    resetState() {
      return initialState;
    }
  },
  effects: () => ({
    async fetchConsultations(_, state) {
      const currentUserRef = firebase
        .firestore()
        .collection('users')
        .doc(state.authentication.user?.id);

      const querySnapshot = await firebase
        .firestore()
        .collection('consultations')
        .where('doctor', '==', currentUserRef)
        .get();

      const disabledUsersIds = await this.getDisabledUsersIds();

      const consultations = (
        await Promise.all(
          querySnapshot.docs.map(async consultation => {
            const patient = await consultation.data().patient.get();

            if (
              disabledUsersIds?.some(
                disabledUserId => disabledUserId === patient.id
              )
            ) {
              return;
            }

            const messages = await firebase
              .firestore()
              .collection(`consultations/${consultation.id}/messages`)
              .orderBy('createdAt', 'desc')
              .limit(1)
              .get();

            const lastMessage = messages.docs?.[0].data();

            return {
              ...consultation.data(),
              id: consultation.id,
              patient: {
                ...patient.data(),
                id: patient.id
              },
              lastMessage
            };
          })
        )
      ).filter(consultation => !!consultation);

      this.setConsultations(consultations);
    },
    async updateJourney({ conversationId, journey }) {
      await firebase
        .firestore()
        .collection('consultations')
        .doc(conversationId)
        .update({ journey });
    },
    async updatePersonalNotes({ consultationId, personalNotes }) {
      await firebase
        .firestore()
        .collection('consultations')
        .doc(consultationId)
        .update({ personalNotes });

      this.updateConsultations({ id: consultationId, personalNotes });
    },
    async updateDoctorLastSeen(consultationId) {
      const doctorLastSeen = firebase.firestore.Timestamp.now();

      await firebase
        .firestore()
        .collection('consultations')
        .doc(consultationId)
        .update({ doctorLastSeen });

      this.updateConsultations({ id: consultationId, doctorLastSeen });
    },
    async getDisabledUsersIds() {
      return (
        await firebase
          .functions()
          .httpsCallable('getDisabledUsersIds')
          .call()
      ).data;
    }
  }),
  selectors: (slice, createSelector) => ({
    consultation() {
      return createSelector(
        slice,
        (state, consultationId) => consultationId,
        (state, consultationId) =>
          state.consultations.find(
            consultation => consultation.id === consultationId
          )
      );
    }
  })
};

export default consultations;
